<template>
  <div class="accent lighten-2 fill-height">
    <MainSearch @search="changeModeToSearch($event)" @cancelSearch="resetMode"/>
    <!-- Virtual Scroller for Rows -->
    <v-container>
      <VirtualGrid
          v-if="loaded && mode"
          :items="newItems"
          :loader="reachedMax ? null : LoaderComponent"
          :updateFunction="pullNewDataWithDelay"
          :updateTriggerMargin="reachedMax ? -100 : 100"
      />
      <h3 v-if="!mode" class="mb-4 text-h4 primary--text font-weight-bold text-uppercase">Your search results...</h3>
      <VirtualGrid
          v-if="loaded && !mode"
          :items="searchItems"
          :loader="reachedMax ? null : LoaderComponent"
          :updateFunction="pullSearchDataWithDelay"
          :updateTriggerMargin="reachedMax ? -100 : 100"
      />
    </v-container>
    <!-- End of results component -->
    <div v-if="reachedMax" class="d-flex justify-center align-center my-16">
      <h1 class="my-6 text-center">You have reached the bottom!</h1>
    </div>
  </div>
</template>

<script>
import MainSearch from "@/components/MainSearch.vue";
import PartCard from "@/components/PartCard.vue";
import Loader from "@/components/Loader.vue";
import VirtualGrid from "vue-virtual-grid";
import {mapActions} from "vuex";

export default {
  components: {VirtualGrid, MainSearch},
  emits: ["search", "cancelSearch"],
  data: () => ({
    mode: true, // false = search, true = market
    query: "",
    newItems: [],
    searchItems: [],
    loaded: false,
    LoaderComponent: Loader,
    start: 0,
    limit: 14,
    reachedMax: false,
  }),
  watch: {
    query: function (newQuery) {
      this.searchItems = [];
      this.start = 0;
      this.limit = 14;
      this.reachedMax = false;
      this.loaded = false;
    },
    mode: function (val) {
      if (val) {
        this.start = 0;
        this.limit = 14;
        this.reachedMax = false;
        this.newItems = [];
      } else {
        this.start = 0;
        this.limit = 14;
        this.reachedMax = false;
        this.searchItems = [];
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  mounted() {
    // check for query
    if (this.$route.query.search) {
      this.query = this.$route.query.search;
      this.mode = false;
    }
    this.initializeList();

    this.restoreScrollPosition();
  },
  methods: {
    ...mapActions("data", ["postData"]),
    initializeList() {
      if (this.mode) {
        this.fetchNewItems()
            .catch((error) => {
              if (error) {
                console.error("Failed to load new data", error);
              }
            })
            .then(() => {
              this.loaded = true;
            });
      } else {
        this.fetchSearchItems()
            .catch((error) => {
              if (error) {
                console.error("Failed to load search data", error);
              }
            })
            .then(() => {
              this.loaded = true;
            });
      }
    },
    changeModeToSearch($event) {
      this.reachedMax = false;
      this.query = $event;
      this.mode = false;
      this.initializeList();
    },
    resetMode() {
      this.reachedMax = false;
      this.mode = true;
      // refresh the page
      this.$router.push({name: "Market", query: {search: null}});
    },
    async fetchNewItems() {
      if (this.reachedMax) return;
      if (this.loading) return;
      this.loading = true;
      let response = await this.postData({
        url: "fetchNewParts",
        data: {start: this.start, limit: this.limit},
      });
      if (response["reachedMax"] === true) {
        this.reachedMax = true;
      } else if (response["reachedMax"] === false) {
        this.start += this.limit;
      }
      try {
        response["parts"].forEach((element) => {
          this.newItems.push(this.generateItem(element));
        });
        this.loading = false;
      } catch (error) {
        console.log(this.reachedMax);
        if (this.reachedMax === true) {
          console.log("Reached Max");
        } else {
          console.log(error);
        }
      }
    },
    async fetchSearchItems() {
      if (this.reachedMax) return;
      // So to include the prices, we split the received data into two parts, query and price as it needs to be a separate parameter for PHP
      let data = {}
      // Try to split the query into two parts, if it fails, then it's just a normal query
      try {
        let urlData = this.query.split("-");
        let prices = urlData[1].split("_");
        let priceMin = prices[0];
        let priceMax = prices[1];
        const searchTerms = encodeURI(urlData[0]);
        // This then forms the JSON object to be converted and submitted to the server
        data = {
          start: this.start,
          limit: this.limit,
          searchTerms: urlData[0],
          priceMin: priceMin,
          priceMax: priceMax,
        };
      } catch (e) {
        //console.log(e);
        const searchTerms = encodeURI(this.query);
        data = {
          start: this.start,
          limit: this.limit,
          searchTerms: this.query,
        };
      }
      let response = await this.postData({
        url: "fetchSearch",
        data,
      });
      if (response["reachedMax"] === true) {
        this.reachedMax = true;
      } else if (response["reachedMax"] === false) {
        this.start += this.limit;
      }
      try {
        response["results"].forEach((element) => {
          this.searchItems.push(this.generateItem(element["part"]));
        });
        this.loading = false;
      } catch (error) {
        console.log(this.reachedMax);
        if (this.reachedMax === true) {
          console.log("Reached Max");
        } else {
          console.log(error);
        }
      }
    },
    pullNewDataWithDelay() {
      return new Promise((resolve) =>
          setTimeout(() => resolve(this.fetchNewItems()), 100)
      );
    },
    pullSearchDataWithDelay() {
      return new Promise((resolve) =>
          setTimeout(() => resolve(this.fetchSearchItems()), 100)
      );
    },
    generateItem(itemData) {
      let itemID = this.mode ? this.newItems.length.toString() : this.searchItems.length.toString();
      return {
        id: itemID,
        injected: itemData,
        height: 480,
        columnSpan: 1,
        newRow: false,
        renderComponent: PartCard,
      };
    },
  },
};
</script>

